<template>
  <div class="header-title_container">
    <SuccessIcon class="header-title_icon" />
    <span class="header-title_text">{{ langText?.SHEIN_KEY_PWA_29145 }}</span>
  </div>
</template>

<script setup>
import SuccessIcon from './Icon/SuccessIcon.vue'
defineProps({
  langText: {
    type: Object,
    default: () => {}
  }
})
// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'HeaderTitle'
})
</script>

<style lang="less" scoped>
@vw: 375/100vw;
.header-title_container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6 / @vw;
  .header-title_icon{
    width: 30 / @vw;
    height: 30 / @vw;
  }
  .header-title_text{
    font-size: 16 / @vw;
    font-weight: 700;
    color: #222222;
  }
}
</style>
