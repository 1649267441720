<template>
  <div class="NewUserRights-container">
    <NewRightBg class="NewUserRights_bg" />
    <div
      class="NewUserRights-content"
    >
      <NewUserRightsVue />
    </div>
  </div>
</template>

<script setup>
import NewRightBg from './icon/NewRightBg.vue'
import NewUserRightsVue from 'public/src/pages/login/components/NewUserRights/NewUserRights.vue'
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NewcomerRights'
})
</script>

<style lang="less" scoped>
/* stylelint-disable declaration-no-important */
  .NewUserRights-container{
    .NewUserRights_bg{
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 8px 8px 0 0;
    }
   .NewUserRights-content{
    margin-top: 22px;
    width: 100%;
    min-height: 60px;
    :deep(.page__login-newUserRgiths_single){
      margin-top: 0;
      margin-bottom: 10px;
      background: none !important;
      justify-content: center;
      width: 100%;
      word-break: break-all;
      padding: 8px 16px;
      transform: translateY(-0.5px);
    }
    :deep(.page__login-newUserRgiths_mutilple){
      margin-top: 0;
      background: none !important;
    }
   }
  }
</style>
