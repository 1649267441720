<template>
  <div class="freeShipping-container">
    <FreeShippingBg class="freeShipping_bg-style" />
  </div>
</template>

<script setup>
import FreeShippingBg from './icon/FreeShippingBg.vue'
</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FreeShipping'
})
</script>

<style lang="less" scoped>
.freeShipping-container{
  position: relative;
  top: -1px;
}
.freeShipping_bg-style{
  border-radius: 8px 8px 0 0;
}
</style>
